import React, { FormEvent, ChangeEvent, useState, useEffect, useRef } from 'react';
import { MessageCircle, X } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

import { mappingFunc, RequestTypeKey } from '../../utils/chatbot/idFieldMapping';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { createGlobalStyle } from 'styled-components';
import { validateForm } from '../../utils/chatbot/validation';
import { apiZendeskCall } from '../../utils/chatbot/api/zendesk';
import { ChatHeader } from './ChatHeader';
import { ChatFooter } from './ChatFooter';
import { ChatWidgetProps, FeedbackData, TypeHandleChangeForm } from '../../utils/chatbot/types';
import { ChatMessages } from './ChatMessages';
import { AdvisorTab } from './AdVisorTab';
import { GdprMessage } from './GdprMessage';
import { useChatLogic } from '../../hooks/useChatLogic';
import { ZendeskForm } from './ZendeskForm';
import { CallbackForm } from './CallbackForm';
import { InitialForm } from './InitialForm';
import { FeedbackForm } from './FeedbackForm';
import { FeedbackComment } from './FeedbackComment';
import axios from 'axios';

const GlobalStyle = createGlobalStyle<{ isChatOpen: boolean }>`
  body {
    overflow: ${({ isChatOpen }) => (isChatOpen ? 'hidden' : 'auto')};
  }
  #___gatsby {
    overflow: ${({ isChatOpen }) => (isChatOpen ? 'hidden' : 'auto')};
    position: ${({ isChatOpen }) => (isChatOpen ? 'fixed' : 'static')};
    width: 100%;
  }
`;

export function ChatWidgetV3({ apiUrl }: ChatWidgetProps) {
  const {
    isOpen,
    setIsOpen,
    input,
    setInput,
    chatHistory,
    setChatHistory,
    isTyping,
    currentTypingMessage,
    isLoading,
    handleSubmit,
    lastMessageRef,
    showAdvisorTab,
    setShowAdvisorTab,
    showZendeskForm,
    setShowZendeskForm,
    showCallbackForm,
    setShowCallbackForm,
    showGdprMessage,
    setShowGdprMessage,
    hasFilledInitialForm,
    setHasFilledInitialForm,
    formState,
    setFormState,
    errors,
    setErrors,
    isSubmitting,
    setIsSubmitting,
    tokenImages,
    setTokenImages,
    handleBack,
    ticketId,
    hasSentFeedback,
    setHasSentFeedback
  } = useChatLogic(apiUrl);

  const prefix = process.env.GATSBY_ENVIRONMENT == 'production' ? 'prd' : 'dev';

  const baseUrl = `https://ai-api-${prefix}.exaprint.fr/`;
  const zendeskSubmitTicket = 'submit-zendesk-form/';
  const urlSubmitZendeskForm = baseUrl + zendeskSubmitTicket;
  const feedbackSubmit = 'user-feedback/';
  const urlSubmitFeedback = baseUrl + feedbackSubmit;

  const phoneNumberRegex = /^\+?[0-9]{1,15}$/;

  const isMobile = useIsWindowWidthBelow(600);

  const validRequestTypes = [
    'accompagnement_devis',
    'informations_sur_une_commande_en_cours_',
    'information_sur_votre_réclamation',
    'informations_sur_mon_compte'
  ];

  const [showFeedback, setShowFeedback] = useState(false);
  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  const [, setFeedbackData] = useState<FeedbackData>({
    rating: null,
    comment: '',
    ticket_id: null
  });
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const [hasSubmittedComment, setHasSubmittedComment] = useState(false);

  const handleActivity = () => {
    setLastActivityTime(Date.now());
  };

  useEffect(() => {
    if (!isOpen || hasSentFeedback || showFeedback || showFeedbackComment || chatHistory.length < 2) return;

    const timer = setInterval(() => {
      const inactiveTime = Date.now() - lastActivityTime;
      if (inactiveTime >= 15000) {
        setShowFeedback(true);
        setIsOpen(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastActivityTime, isOpen, hasSentFeedback, showFeedback, showFeedbackComment, chatHistory]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [showFeedback, showFeedbackComment, chatHistory]);

  const handleFeedbackRating = async (rating: number) => {
    setFeedbackData({
      rating: rating,
      comment: '',
      ticket_id: ticketId
    });

    try {
      await axios.post(urlSubmitFeedback, {
        rating: rating,
        comment: '',
        ticket_id: ticketId
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi du rating:", error);
    }

    setShowFeedback(false);
    setShowFeedbackComment(true);
  };

  const handleFeedbackComment = async (comment: string) => {
    try {
      await axios.post(urlSubmitFeedback, {
        comment: comment || '',
        ticket_id: ticketId
      });
      setHasSubmittedComment(true);
      setHasSentFeedback(true);
      setShowFeedbackComment(false);
    } catch (error) {
      console.error("Erreur lors de l'envoi du commentaire:", error);
    }
  };

  const handleCloseFeedbackComment = () => {
    setHasSentFeedback(true);
    setShowFeedbackComment(false);
    if (!hasSubmittedComment) {
      setChatHistory(prev => [
        ...prev,
        ['exabot', 'Merci, votre retour est précieux ! Nous travaillons chaque jour pour vous offrir la meilleure expérience possible. 🙌']
      ]);
    }
  };

  const handleChangeForm: TypeHandleChangeForm = evt => {
    const { name, value } = evt.target;

    if (name === 'phoneNumber') {
      const cleanedValue = value.replace(/[^\d+]/g, '');
      if ((cleanedValue === '+' && value.length === 1) || phoneNumberRegex.test(cleanedValue) || cleanedValue === '') {
        setFormState(prev => ({ ...prev, phoneNumber: cleanedValue }));
        setErrors(prev => ({ ...prev, phoneNumber: '' }));
      } else {
        setErrors(prev => ({ ...prev, phoneNumber: 'Format invalide. Seuls les chiffres et "+" sont autorisés.' }));
      }
    } else {
      setFormState(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleRequestType = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value as RequestTypeKey;
    setFormState({
      ...formState,
      requestType: value,
      requestTypeID: mappingFunc(value),
      secondRequestType: '',
      secondRequestTypeID: 0
    });
  };

  const handleSecondRequestType = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value as RequestTypeKey;
    setFormState({
      ...formState,
      secondRequestType: value,
      secondRequestTypeID: mappingFunc(value)
    });
  };

  async function handleFormSubmit(e: FormEvent<HTMLFormElement>, formType: 'ticket' | 'callback') {
    e.preventDefault();
    setIsSubmitting(true);
    const { newErrors, isValid } = validateForm(formState, formType, showZendeskForm, phoneNumberRegex);
    if (!isValid) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }
    setErrors({});

    const finalFormState =
      formType === 'callback' ? { ...formState, reason: 'callback', formType: 'callback' as const } : { ...formState, formType: 'ticket' as const };

    try {
      await apiZendeskCall(finalFormState, tokenImages, chatHistory, urlSubmitZendeskForm);
      if (formType === 'ticket') {
        setShowZendeskForm(false);
        setChatHistory(prev => [...prev, ['exabot', 'Votre demande a été transmise à notre équipe avec succès.']]);
      } else {
        setShowCallbackForm(false);
        setChatHistory(prev => [...prev, ['exabot', 'Votre demande de rappel a été enregistrée avec succès. Un conseiller vous contactera prochainement.']]);
      }

      setFormState({
        ...formState,
        phoneNumber: '',
        requestType: '',
        secondRequestType: '',
        secondRequestTypeID: 0,
        requestTypeID: 0,
        subject: '',
        description: '',
        quote_number: undefined,
        delivery_points_number: undefined,
        order_number: undefined,
        account_number: '',
        attachment_token: '',
        formType: 'ticket'
      });
    } catch (error) {
      console.error('Erreur lors de la création de la demande:', error);
      alert('Une erreur est survenue lors de la création de la demande. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleInitialFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: Record<string, string> = {};

    if (!formState.userName?.trim()) {
      newErrors.userName = 'Le nom est requis.';
    }

    if (!formState.email?.trim()) {
      newErrors.email = "L'email est requis.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) {
      newErrors.email = "L'email n'est pas valide.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setHasFilledInitialForm(true);
  };

  interface ChatButtonProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
  }

  const ChatButtonPill = ({ isOpen, setIsOpen }: ChatButtonProps) => {
    return (
      <ButtonContainer>
        <PillToggleButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <X size={24} /> : <MessageCircle size={24} />}
          <ButtonText>Chat</ButtonText>
        </PillToggleButton>
      </ButtonContainer>
    );
  };

  return (
    <>
      {isMobile && <GlobalStyle isChatOpen={isOpen} />}
      {isOpen && isMobile && <StyledOverlay onClick={() => setIsOpen(false)} />}
      {isOpen && (
        <ChatContainer>
          <ChatWindow onClick={handleActivity}>
            <ChatHeader
              isMobile={isMobile}
              isAdvisorTab={showAdvisorTab}
              isZendeskForm={showZendeskForm}
              isCallbackForm={showCallbackForm}
              onClickAdvisorTab={() => setShowAdvisorTab(true)}
              onClickBack={handleBack}
              onClickClose={() => setIsOpen(false)}
            />
            <ChatBody ref={chatBodyRef}>
              <ChatContent>
                {showAdvisorTab ? (
                  <AdvisorTab setShowAdvisorTab={setShowAdvisorTab} setShowCallbackForm={setShowCallbackForm} setShowZendeskForm={setShowZendeskForm} />
                ) : showZendeskForm ? (
                  <ZendeskForm
                    formState={formState}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    isMobile={isMobile}
                    validRequestTypes={validRequestTypes}
                    handleChangeForm={handleChangeForm}
                    handleRequestType={handleRequestType}
                    handleSecondRequestType={handleSecondRequestType}
                    setTokenImages={setTokenImages}
                    onSubmit={e => handleFormSubmit(e, 'ticket')}
                  />
                ) : showCallbackForm ? (
                  <CallbackForm
                    formState={formState}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    validRequestTypes={validRequestTypes}
                    handleChangeForm={handleChangeForm}
                    handleRequestType={handleRequestType}
                    handleSecondRequestType={handleSecondRequestType}
                    onSubmit={e => handleFormSubmit(e, 'callback')}
                  />
                ) : !hasFilledInitialForm ? (
                  <InitialForm formState={formState} errors={errors} handleChangeForm={handleChangeForm} onSubmit={handleInitialFormSubmit} />
                ) : (
                  <ChatMessages
                    chatHistory={chatHistory}
                    currentTypingMessage={currentTypingMessage}
                    isLoading={isLoading}
                    isTyping={isTyping}
                    lastMessageRef={lastMessageRef}
                  />
                )}
                {showFeedbackComment && <FeedbackComment onSubmit={handleFeedbackComment} onClose={handleCloseFeedbackComment} />}
              </ChatContent>

              {showGdprMessage && !showAdvisorTab && !showZendeskForm && !showCallbackForm && hasFilledInitialForm && chatHistory.length <= 1 && (
                <GdprMessage onClose={setShowGdprMessage} />
              )}
            </ChatBody>

            {showFeedback && !hasSentFeedback && !showFeedbackComment && hasFilledInitialForm && !showAdvisorTab && !showZendeskForm && !showCallbackForm && (
              <FeedbackContainer>
                <FeedbackForm onRating={handleFeedbackRating} />
              </FeedbackContainer>
            )}

            {!showAdvisorTab && !showZendeskForm && !showCallbackForm && hasFilledInitialForm && (
              <ChatFooter
                inputValue={input}
                isLoading={isLoading}
                onSubmit={handleSubmit}
                onChangeInput={setInput}
                showGdprMessage={showGdprMessage}
                setShowGdprMessage={setShowGdprMessage}
                hasFilledInitialForm={hasFilledInitialForm}
              />
            )}
          </ChatWindow>
        </ChatContainer>
      )}
      <ChatButtonPill isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    transform: scale(1.07);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

const ButtonText = styled.span`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
`;

const PillToggleButton = styled.button`
  background-color: #51c1ec;
  color: white;
  border: none;
  border-radius: 28px;
  height: 56px;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);

  box-shadow: 0px 38px 11px 0px rgba(0, 0, 0, 0), 0px 24px 10px 0px rgba(0, 0, 0, 0.01), 0px 14px 8px 0px rgba(0, 0, 0, 0.05),
    0px 6px 6px 0px rgba(0, 0, 0, 0.09), 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4caed1;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ChatContainer = styled.div<{ customHeight: number }>`
  position: fixed;
  bottom: 9.5%;
  right: 2.5%;
  z-index: 10000;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    width: 100%;
    max-height: 100vh;
    height: ${props => (props.customHeight ? '70vh' : '100%')};
  }
`;

export const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
`;

const ChatWindow = styled.div`
  background-color: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 420px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-height: 80vh;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: none;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 10%;
  right: 3%;
  z-index: 9999;

  @media (max-width: 600px) {
    bottom: 2%;
    right: 4%;
  }
`;

const ChatBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #ebebeb;
  scroll-behavior: smooth;
  position: relative;
`;

const ChatContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: min-content;
`;

const FeedbackContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  flex-shrink: 0;
  background-color: #fff8eb;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
